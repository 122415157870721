import React from "react";
import Styles from "./SourceDestination.module.scss";

//Components
import Typography from "@vahak/core-ui/dist/components/Typography";

//Assets
import SourceIcon from "@vahak/core/dist/standard-icons/source.svg";
import DestinationIcon from "@vahak/core/dist/standard-icons/destination.svg";
import COLORS from "@vahak/core-ui/dist/constants/colors";

interface SourceDestinationProps {
    id?: string;
    source?: string;
    destination?: string;
    sourceCity?: string;
    sourceState?: string;
    destCity?: string;
    destState?: string;
    distanceInKm?: number;
}

const SourceDestination = ({
    id,
    destination,
    source,
    destCity,
    destState,
    sourceCity,
    sourceState,
    distanceInKm
}: SourceDestinationProps) => {
    // const src = source || (sourceCity || sourceState ? [sourceCity, sourceState].filter((v) => !!v).join(", ") : " ");
    // const dest = destination || (destCity || destState ? [destCity, destState].filter((v) => !!v).join(", ") : " ");
    return (
        <div className={Styles.main}>
            <div className={Styles.locations}>
                <div className={Styles.location}>
                    <div className={Styles.icon}>
                        <SourceIcon />
                    </div>
                    <div className={Styles.info} id={`${id}--source`}>
                        <Typography weight="medium" size="sm" color={COLORS.GREY_900}>
                            {source || sourceCity}
                            {destState ? `, ` : ""}
                        </Typography>
                        {sourceState ? (
                            <Typography weight="medium" size="sm" color={COLORS.GREY_500}>
                                {sourceState}
                            </Typography>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
                <div className={Styles.location} id={`${id}--destination`}>
                    <div className={Styles.icon}>
                        <DestinationIcon />
                    </div>
                    <div className={Styles.info}>
                        <Typography weight="medium" size="sm" color={COLORS.GREY_900}>
                            {destination || destCity}
                            {destState ? `, ` : ""}
                        </Typography>
                        {destState ? (
                            <Typography weight="medium" size="sm" color={COLORS.GREY_500}>
                                {destState}
                            </Typography>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
            {distanceInKm ? (
                <div className={Styles.distanceInKm} id={`${id}--kilometer`}>
                    <Typography weight="medium" size="sm" color={COLORS.GREY_700}>
                        {distanceInKm} km
                    </Typography>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default SourceDestination;
