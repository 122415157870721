import React from "react";
import IconWrapper from "@vahak/core-ui/dist/components/IconWrapper";
import Typography, { WeightType } from "@vahak/core-ui/dist/components/Typography";
import Flex from "@vahak/core-ui/dist/layout/Flex";
//
import {
    LORRY_CAPACITY_UNIT_TYPES,
    LORRY_CATEGORIES_ENUM,
    getLorryDetailsByType
} from "@vahak/core/dist/constants/lorryTypes";
//
import LoadIcon from "@vahak/core/dist/icons/load-grey.svg";
import LorryIcon from "@vahak/core/dist/icons/lorry-icon-grey.svg";
//
import { filteredArray } from "@vahak/core-ui/dist/methods/array";
import { DotSymbol } from "@vahak/core-ui/dist/constants/symbol";
import { joinComponents } from "../../../../methods/joinComponents";

interface LoadLorryQuickInfoProps {
    type?: "load" | "lorry";
    lorryType?: LORRY_CATEGORIES_ENUM;
    material?: string;
    capacity?: number;
    capacityType?: LORRY_CAPACITY_UNIT_TYPES;
    tyres?: number;
    lorrySize?: number;
    lorrySizeUnit?: string;
    fontWeight?: WeightType;
    bodyType?: string;
}
const LoadLorryQuickInfo = ({
    type,
    capacity,
    lorryType,
    material,
    capacityType,
    tyres,
    lorrySize,
    lorrySizeUnit,
    fontWeight,
    bodyType
}: LoadLorryQuickInfoProps) => {
    const getLorryDetails = () => {
        const { label, unit } = getLorryDetailsByType(lorryType!) ?? {};

        return [
            label,
            capacity ? `${capacity} ${unit}` : undefined,
            bodyType ? bodyType : undefined,
            tyres ? `${tyres} Tyres` : undefined,
            lorrySize && lorrySizeUnit ? `${lorrySize} ${lorrySizeUnit}` : undefined
        ];
    };
    const details = filteredArray(
        type === "load"
            ? [material, capacity ? `${capacity} ${capacityType}` : undefined]
            : [...(lorryType != undefined ? getLorryDetails() : [])]
    );
    return (
        <Flex display="inline-flex" gap={5} alignItems="center">
            <IconWrapper gap={"5px"} key={"icon"} height={16} width={16}>
                {type === "load" ? <LoadIcon /> : <LorryIcon />}
            </IconWrapper>

            {joinComponents(
                details?.map((dt) => (
                    <Typography size="sm" mSize="s" weight={fontWeight ? fontWeight : "medium"} key={dt}>
                        {dt}
                    </Typography>
                )) ?? [],
                DotSymbol
            )}
        </Flex>
    );
};
export default LoadLorryQuickInfo;
