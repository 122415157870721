import React from "react";
import Styles from "./ExpectedPrice.module.scss";
import Typography from "@vahak/core-ui/dist/components/Typography";
import Input, { InputChangeHandler, InputFocusHandler } from "@vahak/core-ui/dist/components/Input";
import { Rupee } from "@vahak/core-ui/dist/constants/symbol";
import COLORS from "@vahak/core-ui/dist/constants/colors";
import { getPriceString } from "@vahak/core-ui/dist/methods";

interface ExpectedPriceProps {
    id?: string;
    errorMsg?: string;
    expectedAmount?: number;
    expectedAmountFieldName?: string;
    expectedSuggestionMin?: number;
    expectedSuggestionMax?: number;
    onChange?: InputChangeHandler;
    isRequired?: boolean;
    quantity?: number;
    onFocus?: InputFocusHandler;
    handleBlur?: () => void;
    autoFocus?: boolean;
    additionalAmount?: number;
}

const ExpectedPrice = ({
    expectedAmount,
    expectedSuggestionMax = 0,
    expectedSuggestionMin = 0,
    onChange,
    onFocus,
    expectedAmountFieldName = "expectedAmount",
    id = "expectedAmount",
    errorMsg,
    isRequired,
    quantity,
    handleBlur,
    autoFocus = true,
    additionalAmount
}: ExpectedPriceProps) => {
    return (
        <div className={Styles.main}>
            {!!expectedSuggestionMin && !!expectedSuggestionMax && (
                <div className={Styles.expectedPrice}>
                    <span className={Styles.label}>Suggested Price: </span>
                    <span className={Styles.value}>
                        Rs {expectedSuggestionMin?.toLocaleString()} - Rs {expectedSuggestionMax?.toLocaleString()}
                    </span>
                </div>
            )}

            <div className={Styles.inputContainer}>
                <div className={Styles.row1}>
                    <div className={Styles.textInput}>
                        <span className={Styles.label}>
                            Enter Expected Price
                            {isRequired && <span className={Styles.required}> *</span>}
                        </span>
                        <span className={Styles.prefixWrapper}>
                            <span className={Styles.prefix}>{Rupee} </span>
                            <Input
                                onChange={onChange!}
                                name={expectedAmountFieldName}
                                id={id + "_txtf"}
                                value={
                                    (Number(expectedAmount)
                                        ? Number(expectedAmount)?.toLocaleString("en-in")
                                        : "") as any
                                }
                                type="number"
                                placeholder="1000"
                                inputMode="numeric"
                                autoFocus={autoFocus}
                                onFocus={onFocus} // for error msg
                                onBlur={handleBlur} // for event tracking
                            />
                        </span>
                    </div>
                    <div className={Styles.info}>
                        <Typography weight="semibold" size="m" mSize="xs" color={COLORS.GREY_900}>
                            Total Freight
                        </Typography>
                        <Typography weight="semibold" size="xs" mSize="xs" color={COLORS.GREY_400}>
                            for {quantity} Tonnes
                        </Typography>
                    </div>
                </div>
                {!!additionalAmount && (
                    <div className={Styles.row2}>
                        <Typography size="sm" mSize="xs" color={COLORS.GREY_500}>
                            (Additional charges{" "}
                            <Typography weight="semibold">{getPriceString(additionalAmount)}</Typography>)
                        </Typography>
                    </div>
                )}

                {!!errorMsg && (
                    <div id={id + "_error"}>
                        <Typography size="s" color={COLORS.RED}>
                            {errorMsg}
                        </Typography>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ExpectedPrice;
