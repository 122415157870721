import IconWrapper from "@vahak/core-ui/dist/components/IconWrapper";
import Typography from "@vahak/core-ui/dist/components/Typography/Typography";
import React from "react";
import CallBackUsIcon from "@vahak/core/dist/icons/callBackUs.svg";
import Styles from "./Helpdesk.module.scss";
import COLORS from "@vahak/core-ui/dist/constants/colors";

interface HelpdeskPropsType {
    onClick?: () => void;
    id?: string;
    bgColor?: COLORS | "transparent";
    faceColor?: COLORS;
    showLabel?: boolean;
}
const Helpdesk = ({
    onClick,
    id,
    bgColor = COLORS.BLUE_100,
    faceColor = COLORS.GREY_600,
    showLabel = true
}: HelpdeskPropsType) => {
    return (
        <div
            data-help-desk-wrapper
            className={Styles.main}
            onClick={onClick}
            id={id}
            style={{ backgroundColor: bgColor }}
        >
            <IconWrapper svgFillColor={faceColor}>
                <CallBackUsIcon />
            </IconWrapper>
            {showLabel && (
                <Typography data-help-desk-label size="sm" as="label" color={faceColor}>
                    Help
                </Typography>
            )}
        </div>
    );
};

export default Helpdesk;
